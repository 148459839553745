.shorten__form {
  background: var(--pri-darkViolet);
  background-image: url("../../images/bg-shorten-mobile.svg");
  background-repeat: no-repeat;
  background-position: top right;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid var(--darkBlue);
}

.shorten__input {
  display: block;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  padding: 1rem 0.75rem;
  width: 100%;
  color: var(--darkBlue);
}

.shorten__input:focus {
  outline: 2px solid var(--pri-cyan);
}

.shorten__input:focus::placeholder {
  color: var(--pri-darkViolet);
}

.shorten__input-error {
  outline: 2px solid var(--sec-red);
}

.shorten__input-error::placeholder {
  color: var(--sec-red);
}

.short__form--error {
  color: var(--sec-red);
  display: block;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  font-style: italic;
}

.shorten__btn {
  display: block;
  border-radius: 5px;
  width: 100%;
  white-space: nowrap;
  color: white;
  font-weight: 700;
  padding: 0.7em 1.25em;
  font-size: 1.25rem;
  background: var(--pri-cyan);
  margin-top: 1rem;
}

.shorten__btn:hover {
  background: var(--pri-cyan-hover);
}

@media only screen and (min-width: 1024px) {
  .shorten__form {
    display: flex;
    justify-content: space-between;
    background-image: url("../../images/bg-shorten-desktop.svg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2.5rem;
  }

  .shorten__inputContainer {
    width: 90%;
  }
  .shorten__input {
    width: 96%;
  }

  .shorten__btn {
    font-size: 1.25rem;
    margin-top: 0;
    width: 18%;
    align-self: start;
  }
}
