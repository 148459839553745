.stats {
  padding: 5rem 0;
  background: #f0f1f6;
}

.stats__shortenUrlWrapper {
  margin-top: -10.35rem;
}

.stats__header {
  margin: 0 auto;
  margin-top: 5rem;
  text-align: center;
  max-width: 30rem;
}

.stats__title {
  font-size: 1.75rem;
  color: var(--pri-darkViolet);
}

.stats__description {
  color: var(--grayViolet);
  margin-top: 1rem;
}

.line {
  border-left: 7px solid var(--pri-cyan);
  width: 1px;
  height: 4rem;
  margin: 0 auto;
}

.stats__card--container {
  margin-top: 6rem;
}

.stats__card {
  position: relative;
  text-align: center;
  background: white;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 2rem;
  padding: 2.5rem 1.5rem;
  padding-top: 4.5rem;
  max-width: 24rem;
}

.stats__card--iconContainer {
  background: var(--pri-darkViolet);
  border-radius: 100%;
  width: 5.5rem;
  height: 5.5rem;
  display: flex;
  margin: 0 auto;
  margin-top: -7.5rem;
}

.stats__card--icon {
  margin: auto;
}

.stats__card--title {
  font-size: 1.25rem;
  margin-top: 2rem;
}

.stats__card--description {
  margin-top: 1rem;
  color: var(--grayViolet);
}

@media only screen and (min-width: 1024px) {
  .stats {
    padding-bottom: 8rem;
  }
}

@media only screen and (min-width: 1200px) {
  .stats__card--container {
    display: grid;
    grid-template-columns: 1fr 2rem 1fr 2rem 1fr;
    width: 100%;
    height: 22rem;
  }

  .stats__card {
    text-align: left;
    margin: 0;
    width: 100%;
  }

  .stats__title {
    font-size: 2rem;
  }

  .stats__card--iconContainer {
    margin-left: 0.5rem;
  }

  .line {
    background: var(--pri-cyan);
    border: none;
    width: 2rem;
    height: 8px;
    margin: 0;
    align-self: center;
  }

  .card1 {
    align-self: start;
  }

  .card2 {
    align-self: center;
  }

  .card3 {
    align-self: end;
  }
}
