@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap");

:root {
  --pri-cyan: hsl(180, 66%, 49%);
  --pri-darkViolet: hsl(257, 27%, 26%);
  --pri-darkViolet-hover: hsl(258, 25%, 41%);

  --pri-cyan-hover: hsl(180, 55%, 64%);

  --sec-red: hsl(0, 87%, 67%);

  --gray: hsl(0, 0%, 75%);
  --grayViolet: hsl(257, 7%, 63%);
  --darkBlue: hsl(255, 11%, 22%);
  --darkViolet: hsl(260, 8%, 14%);

  --desktop-breakpoint: 1200px;
}

body {
  font-size: 16px;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  border: none;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  max-width: var(--desktop-breakpoint);
  margin: 0 auto;
  padding: 0 1.25rem;
}

.row {
  display: flex;
}
