.cta {
  padding: 3rem 0;
  background-color: var(--pri-darkViolet);
  background-image: url("../../images/bg-boost-mobile.svg");
  background-position: top right;
  background-repeat: no-repeat;
}

.cta__content {
  text-align: center;
}

.cta__title {
  color: white;
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0;
}

.cta__button {
  border: none;
  font-size: 1.25rem;
  border-radius: 50px;
  background: var(--pri-cyan);
  color: white;
  font-weight: 700;
  padding: 0.75em 1.5em;
  margin-top: 1.25rem;
}

.cta__button:hover {
  background: var(--pri-cyan-hover);
}

@media only screen and (min-width: 768px) {
  .cta {
    background-image: url("../../images/bg-boost-desktop.svg");
  }

  .cta__title {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1024px) {
  .cta__title {
    font-size: 3rem;
  }
}
