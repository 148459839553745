.link__card {
  background: white;
  border-radius: 5px;
  margin-top: 1.25rem;
  padding: 0.95rem;
  position: relative;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.24);
}

.link__card--originalLink {
  width: 100%;
  font-size: 0.85rem;
  color: var(--darkBlue);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.divider {
  height: 1px;
  background: rgba(0, 0, 0, 0.116);
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
}

.link__card--shortLink {
  font-size: 0.85rem;
  display: inline-block;
  margin-top: 1.25rem;
  color: var(--pri-cyan);
}

.link__card--copyLinkBtn {
  display: block;
  font-size: 0.85rem;
  text-align: center;
  width: 100%;
  background: var(--pri-cyan);
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  font-weight: 700;
  padding: 0.5em 1em;
  margin-top: 0.75rem;
}

.link__card--copyLinkBtn:hover {
  background: var(--pri-cyan-hover);
}

.link__card--copyLinkBtn-copied {
  background: var(--pri-darkViolet);
}

.link__card--copyLinkBtn-copied:hover {
  background: var(--pri-darkViolet);
  cursor: default;
}

@media only screen and (min-width: 1024px) {
  .divider {
    display: none;
    visibility: hidden;
  }

  .link__card {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .link__card--originalLink {
    width: 50%;
    font-size: 0.9rem;
  }

  .link__card-lowerSection {
    display: flex;
    align-items: center;
    width: 33%;
  }

  .link__card--shortLink {
    margin-top: 0;
    white-space: nowrap;
    margin-right: 2rem;
    font-size: 0.9rem;
  }

  .link__card--copyLinkBtn {
    margin-top: 0;
  }
}
