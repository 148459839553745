@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap);
:root {
  --pri-cyan: hsl(180, 66%, 49%);
  --pri-darkViolet: hsl(257, 27%, 26%);
  --pri-darkViolet-hover: hsl(258, 25%, 41%);

  --pri-cyan-hover: hsl(180, 55%, 64%);

  --sec-red: hsl(0, 87%, 67%);

  --gray: hsl(0, 0%, 75%);
  --grayViolet: hsl(257, 7%, 63%);
  --darkBlue: hsl(255, 11%, 22%);
  --darkViolet: hsl(260, 8%, 14%);

  --desktop-breakpoint: 1200px;
}

body {
  font-size: 16px;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  border: none;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  max-width: 1200px;
  max-width: var(--desktop-breakpoint);
  margin: 0 auto;
  padding: 0 1.25rem;
}

.row {
  display: flex;
}



.cta {
  padding: 3rem 0;
  background-color: var(--pri-darkViolet);
  background-image: url(/static/media/bg-boost-mobile.4b04bd89.svg);
  background-position: top right;
  background-repeat: no-repeat;
}

.cta__content {
  text-align: center;
}

.cta__title {
  color: white;
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0;
}

.cta__button {
  border: none;
  font-size: 1.25rem;
  border-radius: 50px;
  background: var(--pri-cyan);
  color: white;
  font-weight: 700;
  padding: 0.75em 1.5em;
  margin-top: 1.25rem;
}

.cta__button:hover {
  background: var(--pri-cyan-hover);
}

@media only screen and (min-width: 768px) {
  .cta {
    background-image: url(/static/media/bg-boost-desktop.772154c9.svg);
  }

  .cta__title {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1024px) {
  .cta__title {
    font-size: 3rem;
  }
}

.hero {
  padding-bottom: 10rem;
  padding-top: 3rem;
}

.hero__content {
  margin: 0 auto;
  margin-top: 2rem;
  text-align: center;
  max-width: 36rem;
}

.hero__img {
  width: 100%;
  max-width: 500px;
  animation: slideUpDown 5s ease-in-out infinite;
}

@keyframes slideUpDown {
  0% {
    transform: translateY(-10px) translateX(-10px);
  }
  25% {
    transform: translateY(-7px) translateX(-7px);
  }
  50% {
    transform: translateY(-10px) translateX(-10px);
  }
  75% {
    transform: translateY(-7px) translateX(-7px);
  }
  100% {
    transform: translateY(-10px) translateX(-10px);
  }
}

.align-center {
  text-align: center;
}

.hero__title {
  font-size: 2.5rem;
  line-height: 3rem;
  color: var(--darkBlue);
}

.hero__description {
  color: var(--grayViolet);
  margin-top: 1rem;
  font-size: 1.1rem;
}

.hero__cta {
  margin-top: 2.5rem;
  background: var(--pri-cyan);
  color: white;
  font-weight: 700;
  font-size: 1.25rem;
  padding: 0.75em 1.5em;
  border-radius: 50px;
}

.hero__cta:hover {
  background: var(--pri-cyan-hover);
}

@media only screen and (min-width: 1100px) {
  .hero {
    padding-top: 5rem;
    padding-bottom: 12rem;
  }
  .hero__contentContainer {
    display: flex;
    justify-content: space-between;
  }

  .hero__content {
    text-align: left;
    order: -1;
    margin: 0;
    max-width: 48%;
  }

  .hero__title {
    font-size: 4rem;
    line-height: 5rem;
  }

  .hero__description {
    font-size: 1.25rem;
  }

  .hero__img {
    margin-left: 2rem;
    width: 500px;
    max-height: 350px;
  }
}

.stats {
  padding: 5rem 0;
  background: #f0f1f6;
}

.stats__shortenUrlWrapper {
  margin-top: -10.35rem;
}

.stats__header {
  margin: 0 auto;
  margin-top: 5rem;
  text-align: center;
  max-width: 30rem;
}

.stats__title {
  font-size: 1.75rem;
  color: var(--pri-darkViolet);
}

.stats__description {
  color: var(--grayViolet);
  margin-top: 1rem;
}

.line {
  border-left: 7px solid var(--pri-cyan);
  width: 1px;
  height: 4rem;
  margin: 0 auto;
}

.stats__card--container {
  margin-top: 6rem;
}

.stats__card {
  position: relative;
  text-align: center;
  background: white;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 2rem;
  padding: 2.5rem 1.5rem;
  padding-top: 4.5rem;
  max-width: 24rem;
}

.stats__card--iconContainer {
  background: var(--pri-darkViolet);
  border-radius: 100%;
  width: 5.5rem;
  height: 5.5rem;
  display: flex;
  margin: 0 auto;
  margin-top: -7.5rem;
}

.stats__card--icon {
  margin: auto;
}

.stats__card--title {
  font-size: 1.25rem;
  margin-top: 2rem;
}

.stats__card--description {
  margin-top: 1rem;
  color: var(--grayViolet);
}

@media only screen and (min-width: 1024px) {
  .stats {
    padding-bottom: 8rem;
  }
}

@media only screen and (min-width: 1200px) {
  .stats__card--container {
    display: grid;
    grid-template-columns: 1fr 2rem 1fr 2rem 1fr;
    width: 100%;
    height: 22rem;
  }

  .stats__card {
    text-align: left;
    margin: 0;
    width: 100%;
  }

  .stats__title {
    font-size: 2rem;
  }

  .stats__card--iconContainer {
    margin-left: 0.5rem;
  }

  .line {
    background: var(--pri-cyan);
    border: none;
    width: 2rem;
    height: 8px;
    margin: 0;
    align-self: center;
  }

  .card1 {
    align-self: start;
  }

  .card2 {
    align-self: center;
  }

  .card3 {
    align-self: end;
  }
}

.link__card {
  background: white;
  border-radius: 5px;
  margin-top: 1.25rem;
  padding: 0.95rem;
  position: relative;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.24);
}

.link__card--originalLink {
  width: 100%;
  font-size: 0.85rem;
  color: var(--darkBlue);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.divider {
  height: 1px;
  background: rgba(0, 0, 0, 0.116);
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
}

.link__card--shortLink {
  font-size: 0.85rem;
  display: inline-block;
  margin-top: 1.25rem;
  color: var(--pri-cyan);
}

.link__card--copyLinkBtn {
  display: block;
  font-size: 0.85rem;
  text-align: center;
  width: 100%;
  background: var(--pri-cyan);
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  font-weight: 700;
  padding: 0.5em 1em;
  margin-top: 0.75rem;
}

.link__card--copyLinkBtn:hover {
  background: var(--pri-cyan-hover);
}

.link__card--copyLinkBtn-copied {
  background: var(--pri-darkViolet);
}

.link__card--copyLinkBtn-copied:hover {
  background: var(--pri-darkViolet);
  cursor: default;
}

@media only screen and (min-width: 1024px) {
  .divider {
    display: none;
    visibility: hidden;
  }

  .link__card {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .link__card--originalLink {
    width: 50%;
    font-size: 0.9rem;
  }

  .link__card-lowerSection {
    display: flex;
    align-items: center;
    width: 33%;
  }

  .link__card--shortLink {
    margin-top: 0;
    white-space: nowrap;
    margin-right: 2rem;
    font-size: 0.9rem;
  }

  .link__card--copyLinkBtn {
    margin-top: 0;
  }
}

.shorten__form {
  background: var(--pri-darkViolet);
  background-image: url(/static/media/bg-shorten-mobile.41c34b33.svg);
  background-repeat: no-repeat;
  background-position: top right;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid var(--darkBlue);
}

.shorten__input {
  display: block;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  padding: 1rem 0.75rem;
  width: 100%;
  color: var(--darkBlue);
}

.shorten__input:focus {
  outline: 2px solid var(--pri-cyan);
}

.shorten__input:focus::-webkit-input-placeholder {
  color: var(--pri-darkViolet);
}

.shorten__input:focus:-ms-input-placeholder {
  color: var(--pri-darkViolet);
}

.shorten__input:focus::placeholder {
  color: var(--pri-darkViolet);
}

.shorten__input-error {
  outline: 2px solid var(--sec-red);
}

.shorten__input-error::-webkit-input-placeholder {
  color: var(--sec-red);
}

.shorten__input-error:-ms-input-placeholder {
  color: var(--sec-red);
}

.shorten__input-error::placeholder {
  color: var(--sec-red);
}

.short__form--error {
  color: var(--sec-red);
  display: block;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  font-style: italic;
}

.shorten__btn {
  display: block;
  border-radius: 5px;
  width: 100%;
  white-space: nowrap;
  color: white;
  font-weight: 700;
  padding: 0.7em 1.25em;
  font-size: 1.25rem;
  background: var(--pri-cyan);
  margin-top: 1rem;
}

.shorten__btn:hover {
  background: var(--pri-cyan-hover);
}

@media only screen and (min-width: 1024px) {
  .shorten__form {
    display: flex;
    justify-content: space-between;
    background-image: url(/static/media/bg-shorten-desktop.0d9742f4.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2.5rem;
  }

  .shorten__inputContainer {
    width: 90%;
  }
  .shorten__input {
    width: 96%;
  }

  .shorten__btn {
    font-size: 1.25rem;
    margin-top: 0;
    width: 18%;
    align-self: start;
  }
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  height: 80px;
  background: rgb(255, 255, 255);
  z-index: 100;
  transition-property: box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
}

.scrolled {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.178);
}

.nav {
  display: flex;
  padding: 1rem 1.25rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.nav__dropdown-exit {
  position: fixed;
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  cursor: default;
}

.nav__logo {
  font-size: 2rem;
  font-weight: 700;
  color: var(--darkBlue);
}

.nav-toggle {
  cursor: pointer;
  border: 0;
  width: 3em;
  height: 3em;
  padding: 0em;
  transition: opacity 250ms ease;
  background: transparent;
}

.nav__listContainer {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  background: var(--pri-darkViolet);
  top: 6rem;
  left: 1.25rem;
  right: 1.25rem;
  border-radius: 15px;
  margin: 0;
  padding: 2rem;
  text-align: center;
  transition: opacity 250ms cubic-bezier(0.63, 0.09, 0.385, 0.88);
}

.nav--visible {
  visibility: visible;
  opacity: 1;
}

.nav__list-sec {
  margin-top: 1.25rem;
}

.nav__item + .nav__item {
  margin-top: 1.5rem;
}

.nav__link {
  color: rgb(255, 255, 255);
  font-weight: 700;
  transition-property: color;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

.nav__link:hover,
.nav__link:focus {
  color: var(--pri-cyan);
}

.nav__item--divider {
  height: 1px;
  margin-top: 1.25rem;
  background-color: rgba(255, 255, 255, 0.178);
}

.nav__link--signup {
  display: block;
  background: var(--pri-cyan);
  border-radius: 50px;
  padding: 0.5rem 0;
}

.nav__link--signup:hover {
  color: white;
  background: var(--pri-cyan-hover);
}

/* HAMBURGER STYLES */

.hamburger {
  width: 62%;
  position: relative;
  transition-property: height;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.63, 0.09, 0.385, 0.88);
}

.hamburger,
.hamburger::before,
.hamburger::after {
  display: block;
  margin: 0 auto;
  height: 3px;
  background-color: var(--grayViolet);
}

.hamburger::before,
.hamburger::after {
  content: "";
  width: 100%;
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.63, 0.09, 0.385, 0.88);
}

.hamburger::before {
  transform: translateY(-9px);
}

.hamburger::after {
  transform: translateY(6px);
}

.hamburger-toggled::before {
  transform: rotate(45deg);
}

.hamburger-toggled::after {
  transform: translateY(-2.5px) rotate(-45deg);
}

.hamburger-toggled {
  height: 0;
}

/* END HAMBURGER STYLES */

@media only screen and (min-width: 700px) {
  .nav {
    justify-content: flex-start;
  }

  .nav__dropdown-exit {
    display: none;
    visibility: hidden;
  }

  .nav-toggle {
    display: none;
    visibility: hidden;
  }

  .nav__listContainer {
    position: static;
    display: flex;
    align-items: center;
    visibility: visible;
    background: transparent;
    border-radius: 0;
    opacity: 1;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    margin-left: 3.5rem;
    padding: 0;
    text-align: center;
    list-style: none;
  }

  .nav__item--divider {
    display: none;
    visibility: hidden;
  }

  .nav__list-pri {
    display: flex;
  }

  .nav__list-sec {
    display: flex;
    margin-top: 0;
    align-items: center;
  }

  .nav__item + .nav__item {
    margin: 0;
    margin-left: 1.5rem;
  }

  .nav__link {
    color: var(--gray);
  }

  .nav__link:hover,
  .nav__link:focus {
    color: var(--darkBlue);
  }

  .nav__link--signup {
    display: block;
    background: var(--pri-cyan);
    border-radius: 50px;
    padding: 0.5rem 1em;
    color: white;
  }

  .nav__link--signup:hover {
    background: var(--pri-cyan-hover);
    color: white;
  }
}

.footer {
  background: var(--darkViolet);
  padding: 3rem 0;
}

.footer__logo {
  display: block;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
}

.footer__content {
  text-align: center;
}

.footer__col {
  margin-top: 3rem;
}

.footer__col--title {
  color: white;
  font-weight: 700;
}

.footer__col--linksContainer {
  margin-top: 1.25rem;
}

.footer__col--item {
  margin-top: 0.5rem;
}

.footer__col--link {
  color: var(--gray);
}

.footer__col--link:hover {
  color: var(--pri-cyan);
}

.footer__col--socials {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__col--socialItem + .footer__col--socialItem {
  margin-left: 1.5rem;
}

@media only screen and (min-width: 768px) {
  .footer {
    padding: 0;
    padding-bottom: 2rem;
  }

  .footer__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: 1fr 1fr 1fr; */
    text-align: left;
  }

  .footer__col {
    margin-top: 3rem;
  }

  .footer__logoWrapper {
    grid-column: 1 / 4;
  }

  .footer__logo {
    text-align: left;
    margin-top: 0;
  }
  .footer__col--socials {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1024px) {
  .footer__content {
    grid-template-columns: repeat(4, 1fr);
  }

  .footer__logoWrapper {
    grid-column: 1 / 5;
  }
}

@media only screen and (min-width: 1200px) {
  .footer {
    padding: 3rem 0;
    padding-bottom: 5rem;
  }

  .footer__content {
    grid-template-columns: repeat(6, 1fr);
  }

  .footer__logoWrapper {
    grid-column: 1/3;
    margin-top: 2rem;
  }
}

