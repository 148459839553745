.hero {
  padding-bottom: 10rem;
  padding-top: 3rem;
}

.hero__content {
  margin: 0 auto;
  margin-top: 2rem;
  text-align: center;
  max-width: 36rem;
}

.hero__img {
  width: 100%;
  max-width: 500px;
  animation: slideUpDown 5s ease-in-out infinite;
}

@keyframes slideUpDown {
  0% {
    transform: translateY(-10px) translateX(-10px);
  }
  25% {
    transform: translateY(-7px) translateX(-7px);
  }
  50% {
    transform: translateY(-10px) translateX(-10px);
  }
  75% {
    transform: translateY(-7px) translateX(-7px);
  }
  100% {
    transform: translateY(-10px) translateX(-10px);
  }
}

.align-center {
  text-align: center;
}

.hero__title {
  font-size: 2.5rem;
  line-height: 3rem;
  color: var(--darkBlue);
}

.hero__description {
  color: var(--grayViolet);
  margin-top: 1rem;
  font-size: 1.1rem;
}

.hero__cta {
  margin-top: 2.5rem;
  background: var(--pri-cyan);
  color: white;
  font-weight: 700;
  font-size: 1.25rem;
  padding: 0.75em 1.5em;
  border-radius: 50px;
}

.hero__cta:hover {
  background: var(--pri-cyan-hover);
}

@media only screen and (min-width: 1100px) {
  .hero {
    padding-top: 5rem;
    padding-bottom: 12rem;
  }
  .hero__contentContainer {
    display: flex;
    justify-content: space-between;
  }

  .hero__content {
    text-align: left;
    order: -1;
    margin: 0;
    max-width: 48%;
  }

  .hero__title {
    font-size: 4rem;
    line-height: 5rem;
  }

  .hero__description {
    font-size: 1.25rem;
  }

  .hero__img {
    margin-left: 2rem;
    width: 500px;
    max-height: 350px;
  }
}
