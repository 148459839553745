.footer {
  background: var(--darkViolet);
  padding: 3rem 0;
}

.footer__logo {
  display: block;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
}

.footer__content {
  text-align: center;
}

.footer__col {
  margin-top: 3rem;
}

.footer__col--title {
  color: white;
  font-weight: 700;
}

.footer__col--linksContainer {
  margin-top: 1.25rem;
}

.footer__col--item {
  margin-top: 0.5rem;
}

.footer__col--link {
  color: var(--gray);
}

.footer__col--link:hover {
  color: var(--pri-cyan);
}

.footer__col--socials {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__col--socialItem + .footer__col--socialItem {
  margin-left: 1.5rem;
}

@media only screen and (min-width: 768px) {
  .footer {
    padding: 0;
    padding-bottom: 2rem;
  }

  .footer__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: 1fr 1fr 1fr; */
    text-align: left;
  }

  .footer__col {
    margin-top: 3rem;
  }

  .footer__logoWrapper {
    grid-column: 1 / 4;
  }

  .footer__logo {
    text-align: left;
    margin-top: 0;
  }
  .footer__col--socials {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1024px) {
  .footer__content {
    grid-template-columns: repeat(4, 1fr);
  }

  .footer__logoWrapper {
    grid-column: 1 / 5;
  }
}

@media only screen and (min-width: 1200px) {
  .footer {
    padding: 3rem 0;
    padding-bottom: 5rem;
  }

  .footer__content {
    grid-template-columns: repeat(6, 1fr);
  }

  .footer__logoWrapper {
    grid-column: 1/3;
    margin-top: 2rem;
  }
}
