.sticky-top {
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  height: 80px;
  background: rgb(255, 255, 255);
  z-index: 100;
  transition-property: box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
}

.scrolled {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.178);
}

.nav {
  display: flex;
  padding: 1rem 1.25rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.nav__dropdown-exit {
  position: fixed;
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  cursor: default;
}

.nav__logo {
  font-size: 2rem;
  font-weight: 700;
  color: var(--darkBlue);
}

.nav-toggle {
  cursor: pointer;
  border: 0;
  width: 3em;
  height: 3em;
  padding: 0em;
  transition: opacity 250ms ease;
  background: transparent;
}

.nav__listContainer {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  background: var(--pri-darkViolet);
  top: 6rem;
  left: 1.25rem;
  right: 1.25rem;
  border-radius: 15px;
  margin: 0;
  padding: 2rem;
  text-align: center;
  transition: opacity 250ms cubic-bezier(0.63, 0.09, 0.385, 0.88);
}

.nav--visible {
  visibility: visible;
  opacity: 1;
}

.nav__list-sec {
  margin-top: 1.25rem;
}

.nav__item + .nav__item {
  margin-top: 1.5rem;
}

.nav__link {
  color: rgb(255, 255, 255);
  font-weight: 700;
  transition-property: color;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

.nav__link:hover,
.nav__link:focus {
  color: var(--pri-cyan);
}

.nav__item--divider {
  height: 1px;
  margin-top: 1.25rem;
  background-color: rgba(255, 255, 255, 0.178);
}

.nav__link--signup {
  display: block;
  background: var(--pri-cyan);
  border-radius: 50px;
  padding: 0.5rem 0;
}

.nav__link--signup:hover {
  color: white;
  background: var(--pri-cyan-hover);
}

/* HAMBURGER STYLES */

.hamburger {
  width: 62%;
  position: relative;
  transition-property: height;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.63, 0.09, 0.385, 0.88);
}

.hamburger,
.hamburger::before,
.hamburger::after {
  display: block;
  margin: 0 auto;
  height: 3px;
  background-color: var(--grayViolet);
}

.hamburger::before,
.hamburger::after {
  content: "";
  width: 100%;
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.63, 0.09, 0.385, 0.88);
}

.hamburger::before {
  transform: translateY(-9px);
}

.hamburger::after {
  transform: translateY(6px);
}

.hamburger-toggled::before {
  transform: rotate(45deg);
}

.hamburger-toggled::after {
  transform: translateY(-2.5px) rotate(-45deg);
}

.hamburger-toggled {
  height: 0;
}

/* END HAMBURGER STYLES */

@media only screen and (min-width: 700px) {
  .nav {
    justify-content: flex-start;
  }

  .nav__dropdown-exit {
    display: none;
    visibility: hidden;
  }

  .nav-toggle {
    display: none;
    visibility: hidden;
  }

  .nav__listContainer {
    position: static;
    display: flex;
    align-items: center;
    visibility: visible;
    background: transparent;
    border-radius: 0;
    opacity: 1;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    margin-left: 3.5rem;
    padding: 0;
    text-align: center;
    list-style: none;
  }

  .nav__item--divider {
    display: none;
    visibility: hidden;
  }

  .nav__list-pri {
    display: flex;
  }

  .nav__list-sec {
    display: flex;
    margin-top: 0;
    align-items: center;
  }

  .nav__item + .nav__item {
    margin: 0;
    margin-left: 1.5rem;
  }

  .nav__link {
    color: var(--gray);
  }

  .nav__link:hover,
  .nav__link:focus {
    color: var(--darkBlue);
  }

  .nav__link--signup {
    display: block;
    background: var(--pri-cyan);
    border-radius: 50px;
    padding: 0.5rem 1em;
    color: white;
  }

  .nav__link--signup:hover {
    background: var(--pri-cyan-hover);
    color: white;
  }
}
